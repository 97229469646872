import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import aboutimage from '../img/about.png'
import trackrecordimage from '../img/track-record.jpg'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'


export const AboutPageTemplate = ({
  title,
  heading,
  image,
}) => (
<div>
  <section className="about-header">
  <div className="about-first">
    <div className="about-header-title">
      <h1 data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">Who <br />we are
      </h1>
      </div>

      <img data-aos="fade-left" data-aos-delay="200" data-aos-duration="2000" className="image-header" src={aboutimage} alt="home"/>
    </div>
    <div className="about-second">
    <div className="about-item">
  <div className="about-text">
    <h2 data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000">About Us</h2>
    <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">The ZM Land & Capital team are planning and development specialists with experience managing complex applications and securing optimum outcomes. From initial evaluation through to implementation, we have an excellent track record of delivery built on an innate understanding of the planning and development process.</p>
    </div>
    <div className="blurb-text">
    
    <h3 data-aos="fade-left" data-aos-delay="600" data-aos-duration="2000">We are supported by best-in-class consultants on all our projects and work on a consensual basis with local planning authorities and the communities they serve to deliver appropriate and viable consents.</h3>
    </div>
    </div>
    </div>

    
  </section>
    
  <section className="proccess">
  <div className="proccess-item">
  <div className="proccess-text">
    <h2 data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000">Evaluation</h2>
    <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">ZM Land & Capital adopts a research led approach and undertakes a thorough analytical assessment of all opportunities. We employ the most suitably qualified professionals from a range of disciplines to provide advice on: planning, architecture and landscape design; engineering, highways and environmental issues; costing, legal and other contractual matters.</p>
    </div>
    <div className="proccess-number">
      <h4>01</h4>
    </div>
    <div className="proccess-line">
    </div>
    </div>

    <div className="proccess-item">
    <div className="proccess-line">
    </div>
    <div className="proccess-number-reverse">
      <h4>02</h4>
    </div>
  <div className="proccess-text-reverse">
    <h2 data-aos="fade-left" data-aos-delay="200" data-aos-duration="2000">Business Plans</h2>
    <p data-aos="fade-left" data-aos-delay="400" data-aos-duration="2000">Business Plans contain our investment rationale and strategic thinking for a project and typically include a series of fully costed options that will each contribute to unlocking or creating value. These Business Plans are only actioned and implemented with the full support and commitment of our investors, who are kept regularly apprised and updated throughout the project lifecycle.</p>
    </div>
    </div>
    <div className="proccess-item">
  <div className="proccess-text">
    <h2 data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000">Structure & Finance</h2>
    <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">We have considerable experience in structuring transactions from an ownership, tax and legal perspective, including Joint Ventures and will consider such arrangements on a project by project basis. We have access to multiple sources of finance and through established contacts are able to secure competitive debt packages to support our schemes.</p>
    </div>
    <div className="proccess-number">
      <h4>03</h4>
    </div>
    <div className="proccess-line">
    </div>
    </div>

    <div className="proccess-item">
    <div className="proccess-line">
    </div>
    <div className="proccess-number-reverse">
      <h4>04</h4>
    </div>
  <div className="proccess-text-reverse">
    <h2 data-aos="fade-left" data-aos-delay="200" data-aos-duration="2000">Implementation</h2>
    <p data-aos="fade-left" data-aos-delay="400" data-aos-duration="2000">ZM Land & Capital reviews the planning and development strategy throughout the lifespan of the project and drives the team to deliver a successful planning outcome. Programmes and budgets are continuously monitored to ensure the project remains on track at all times.</p>
    </div>
    </div>
    
  </section>

  <section className="track-record">
  <div className="track-record-text">
    
    <h2 data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000">TRACK RECORD</h2>
    <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">Over the last 30 years we have secured well over 300 planning permissions across the UK, comprising more than 14 million sq. ft. of consented floor space. Our current pipeline is in excess of 3 million sq. ft. with a gross development value of over £1.2 billion.</p>
    <Link to="/blog" data-aos="fade-right" data-aos-delay="600" data-aos-duration="2000"className="main-button">
       View Projects
    </Link>
    </div>

    <div data-aos="fade-left" data-aos-delay="800" data-aos-duration="2000" className="track-record-image">
    <img src={trackrecordimage} alt="track-image" />
      </div>
    
  </section>
</div>

    
)

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  mainimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        image={frontmatter.image}
        projectlist={frontmatter.projectlist}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`